<!-- 题库管理首页 -->
<template>
  <div>
    <div class="main" style="display: flex;">
      <div style="width: 15%;background: #fff;padding: 10px;margin-right:20px;border-radius: 8px;">
        <el-select v-model="brandId" placeholder="品牌" class="cinput ac">
          <el-option v-for="item in brandList" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
        <div style="display: flex;flex-direction: column;justify-content: center;text-align: center;">
          <div v-for="(item, index) in cateGoryList" :key="item.id" style="margin: 10px;font-size: 14px;"
            :class="item.record.id == recordId ? 'changeSel' : 'defaultcolor'" @click="changeTitle(item.record.id)">{{
          item.record.name
        }}
          </div>
        </div>
      </div>
      <div style="width: 80%;">
        <div style="font-size: 26px; font-weight: bold;display:flex">
          <div style="cursor: pointer;padding: 10px 0;" @click="goback">&lt;课程管理</div>
        </div>
        <div style="display: flex;justify-content: space-between;align-items: center;">
          <div style="display: flex;border-radius: 8px 8px 0 0;background: #C6E7E2;line-height: 34px;">
            <div v-for="(item, index) in statusList" :key="index" style="width: 112px;text-align: center;"
              :class="index == courseTitleInd ? 'currentTitle' : 'titlelayout'" @click="courseTitle(index)">{{ item }}
            </div>
          </div>
          <!-- <el-button type="primary" style="height: 34px; line-height: 10px;background: #00A78E;" class="cinput"
              @click="addcontent2">+添加课程包</el-button> -->
          <el-button type="primary" style="height: 34px; line-height: 10px;background: #00A78E;" class="cinput"
            @click="addCourseTag">+添加课程包</el-button>
          <!-- <el-button type="primary" style="height: 34px; line-height: 10px;background: #00A78E;" class="cinput"
              @click="dialogVisibleContent = true">内容管理</el-button> -->
        </div>
        <div
          style="height: 60px;background: #fff;display: flex;justify-content: space-between;padding: 0 20px;align-items: center;">
          <el-input placeholder="请输入课程包名称" prefix-icon="el-icon-search" v-model="coursePageName" style="width: 232px;">
          </el-input>
          <div style="display: flex;align-items: center;">
            <div style="color: #3D3D3D;font-size: 14px;margin-left: 10px;">主讲老师筛选:</div>
            <el-select v-model="teacherMain" placeholder="老师" clearable class="cinput ac" @change='getcourseList'>
              <el-option v-for="item in listTeacher" :key="item.userId" :label="item.name" :value="item.userId">
              </el-option>
            </el-select>
          </div>
        </div>
        <el-table :header-cell-style="{ background: '#F7F7F7' }" :data="tableData"
          style="width: 100%; min-height: 600px" @selection-change="handleSelectionChange">
          <!-- <el-table-column type="selection" align="center" width="55" />
          <el-table-column type="index" width="50"></el-table-column>
          <el-table-column prop="date" align="center">
            <template slot-scope="scope">
              <div>

              </div>
            </template>
</el-table-column> -->
          <el-table-column prop="record.name" align="center" label="课程包名称"></el-table-column>
          <el-table-column prop="record.teacherName" align="center" label="主讲老师"></el-table-column>
          <el-table-column prop="name" align="center" label="课程类型">
            <template slot-scope="scope">
              <div
                :class="scope.row.record.configValue == 2 || scope.row.record.configValue == 3 ? 'cstatus1' : scope.row.record.configValue == 4 || scope.row.record.configValue == 5 ? 'cstatus2' : 'cstatus3'">
                {{
          scope.row.record.configValue == 2 || scope.row.record.configValue == 3 ? "会员课程" :
            scope.row.record.configValue == 4 || scope.row.record.configValue == 5 ? "付费课程" : "免费课程"
        }}
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="name" align="center" label="名称"></el-table-column> -->
          <el-table-column prop="record.weight" align="center" label="权重"></el-table-column>
          <el-table-column prop="name" align="center" label="点赞量"></el-table-column>
          <el-table-column prop="teacherName" align="center" label="是否推荐课程">
            <template slot-scope="scope">
              <div :style="{ color: scope.row.homeItem && scope.row.homeItem.isValidity == 1 ? '#54C97F' : '#BAC1BD' }">
                {{ scope.row.homeItem && scope.row.homeItem.isValidity == 1 ? '是' : '否' }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="teacherName" align="center" label="是否考试">
            <template slot-scope="scope">
              <div :style="{ color: (scope.row.record.configValue) % 2 == 0 ? '#54C97F' : '#BAC1BD' }">
                {{ (scope.row.record.configValue) % 2 == 0 ? '考试' : '不考试' }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="teacherName" align="center" label="操作" width="200">
            <template slot-scope="scope">
              <div style="display: flex; justify-content: space-around">

                <el-link type="primary" :underline="false" @click="details(scope.row)">课程内容
                </el-link>
                <!-- <el-link type="primary" :underline="false" @click="details2(scope.row)" v-if="!scope.row.teacherName">
                  课程内容
                  </el-link> -->
                <!-- <el-link type="primary" :underline="false" @click="lookcontent3(scope.row)"
                  v-if="!scope.row.teacherName">
                  添加子集</el-link>
                <el-link type="primary" :underline="false" @click="lookcontent4(scope.row)"
                  v-if="!scope.row.teacherName">
                    查看子集</el-link> -->
                <!-- <el-link type="primary" :underline="false" @click="addtext(scope.row)" v-if="scope.row.teacherName">添加内容
                </el-link>
                <el-link type="primary" :underline="false" @click="addtext2(scope.row)" v-if="scope.row.teacherName">
                  课程内容
                  </el-link> -->

                <!-- <el-link type="primary" :underline="false" @click="addtext4(scope.row)">
                  试题管理
                </el-link> -->
                <el-link type="primary" :underline="false" @click="openQuestionList(scope.row)">
                  试题管理
                </el-link>

                <el-dropdown @command="handleCommand">
                  <img src="../../images/more.png" alt="" style="width: 24px;height: 24px;">
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-for="(item, index) in dropdownList"
                      :command="composeValue(item.value, scope.row)" :key="index">{{ item.name }}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <!-- <el-link type="primary" :underline="false" @click="dele(scope.row)">删除</el-link>
                <el-link type="primary" :underline="false" v-if="radio == '2'" @click="lookvideo(scope.row)">查看视频
                </el-link>
                <el-link type="primary" :underline="false" v-if="radio == '3'" @click="lookcontent(scope.row)">查看富文本
                  </el-link> -->

              </div>
            </template>
          </el-table-column>
        </el-table>
        <!-- <div style="
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-top:20px
        ">
          <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="pageIndex" :page-sizes="[10, 15, 20]" :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
          </div> -->
      </div>
    </div>

    <el-dialog title="添加课程包" :visible.sync="dialogVisible" width="60%" class="dialog1">
      <div style="display: flex;justify-content: space-between;height: 100%;">
        <div style="width: 50%;border-right: 1px solid #E5E5E5;">
          <div style="display: flex;align-items: center;font-size: 14px;width: 100px;justify-content: flex-end;">
            <div style="width: 4px;height: 13px;border-radius: 50px;background: #00A78E;margin-right: 4px;"></div>
            <div>基础设置</div>
          </div>
          <div>
            <div style="display: flex;align-items: center;">
              <div style="width: 120px;text-align: right;">课程包名称：</div>
              <el-input v-model="name" placeholder="请输入课程包名称" style="width: 264px;"></el-input>
            </div>
            <div style="display: flex;align-items: center;margin: 16px 0;">
              <div style="width: 120px;text-align: right;">授课老师：</div>
              <el-select v-model="teacherUserId" filterable placeholder="选择讲师" clearable class="cinput ac"
                style="width: 160px;">
                <el-option v-for="item in listTeacher" :key="item.userId" :label="item.name" :value="item.userId">
                </el-option>
              </el-select>
              <el-link type="primary" :underline="false" style="margin-left:10px" @click="addteac">
                +添加老师
              </el-link>
            </div>
            <div style="display: flex;align-items: center;margin: 16px 0;">
              <div style="width: 120px;text-align: right;">课程权重：</div>
              <el-input v-model="weight" placeholder="请输入数字" style="width: 104px;"></el-input>
            </div>
            <div style="display: flex;align-items: center;margin: 16px 0;">
              <div style="width: 120px;text-align: right;">课程难度：</div>
              <el-select v-model="tag" filterable placeholder="选择难度" clearable class="cinput ac" style="width: 160px;">
                <el-option v-for="item in tagList" :key="item.value" :label="item.name" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div style="display: flex;margin: 16px 0;">
              <div style="width: 120px;text-align: right;">课程包简介：</div>
              <el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="des" style="width: 264px;"
                maxlength="108" show-word-limit>
              </el-input>
            </div>
            <div style="display: flex;align-items: center;margin: 16px 0;">
              <div style="width: 120px;text-align: right;">课程包封面</div>
              <img :src="picUri" alt="" style="width: 104px;height: 104px;" v-if="picUri">
              <input type="file" @change="uploadimgpicUri" id='file2' accept='image/*' ref='fileimgCourse'
                style="color:#fff" v-if="!picUri" />
              <el-link type="primary" :underline="false" @click="picUri = ''" v-if="picUri">删除</el-link>
            </div>
            <div
              style="display: flex;align-items: center;font-size: 14px;margin-top: 50px;width: 100px;justify-content: flex-end;">
              <div style="width: 4px;height: 13px;border-radius: 50px;background: #00A78E;margin-right: 4px;">
              </div>
              <div>付费设置</div>
            </div>
            <div style="margin: 20px 0;">
              <el-radio-group v-model="configValue1" text-color="#00A78E">
                <el-radio :label="2">会员课程</el-radio>
                <el-radio :label="4">付费课程</el-radio>
                <el-radio :label="0">免费课程</el-radio>
              </el-radio-group>
            </div>
            <div style="display: flex;align-items: center;font-size: 14px;margin-top: 50px;" v-if="configValue1 == 4">
              <div style="width: 4px;height: 13px;border-radius: 50px;background: #00A78E;margin-right: 4px;">
              </div>
              <div>积分</div>
              <el-input v-model="pricePoint" placeholder="请输入数字" style="width: 104px;"></el-input>
              <div>价格</div>
              <el-input v-model="priceMoney" placeholder="请输入数字" style="width: 104px;"></el-input>
              <div>元</div>
            </div>
            <div
              style="display: flex;align-items: center;font-size: 14px;margin-top: 50px;width: 100px;justify-content: flex-end;">
              <div style="width: 4px;height: 13px;border-radius: 50px;background: #00A78E;margin-right: 4px;">
              </div>
              <div>不考试</div>
              <el-switch v-model="configValue2" active-color="#13ce66">
              </el-switch>
            </div>
            <div
              style="display: flex;align-items: center;font-size: 14px;margin-top: 50px;width: 115px;justify-content: flex-end;">
              <div style="width: 4px;height: 13px;border-radius: 50px;background: #00A78E;margin-right: 4px;">
              </div>
              <div>推荐设置</div>
              <el-switch v-model="homeItem.isValidity" active-color="#13ce66">
              </el-switch>
            </div>
            <div style="display: flex;align-items: center;margin: 16px 0;" v-if="homeItem.isValidity">
              <div style="width: 120px;text-align: right;">推荐页封面：</div>

              <img :src="homeItem.picUri" alt="" style="width: 104px;height: 104px;" v-if="homeItem.picUri">
              <input type="file" @change="uploadimgpic" id='file2' accept='image/*' ref='fileimgPic' style="color:#fff"
                v-if="!homeItem.picUri" />
              <el-link type="primary" :underline="false" @click="homeItem.picUri = ''" v-if="homeItem.picUri">删除
              </el-link>
            </div>
          </div>
          <div
            style="display: flex;align-items: center;font-size: 14px;margin-top: 50px;width: 100px;justify-content: flex-end;">
            <div style="width: 4px;height: 13px;border-radius: 50px;background: #00A78E;margin-right: 4px;">
            </div>
            <div>积分经验设置</div>
          </div>
          <div style="display: flex;align-items: center;margin: 16px 0;">
            <div style="width: 120px;text-align: right;">课程包积分：</div>
            <el-input v-model="point" placeholder="请输入数字" style="width: 104px;"></el-input>
          </div>
          <div style="display: flex;align-items: center;margin: 16px 0;">
            <div style="width: 120px;text-align: right;">经验值：</div>
            <el-input v-model="experience" placeholder="请输入数字" style="width: 104px;"></el-input>
          </div>
        </div>
        <div style="width: 50%;">
          <div style="display: flex;align-items: center;font-size: 14px;" v-if="configValue1 == 4">
            <div style="width: 4px;height: 13px;border-radius: 50px;background: #00A78E;margin-right: 4px;"></div>
            <div>课程包介绍</div>
          </div>
          <quill-editor style="height:80%;width:100%;" v-model="msg" ref="richAnalysis" :options="options2"
            @change="quillchange" v-if="configValue1 == 4"></quill-editor>
          <div style="position: absolute;bottom: 0;display: flex;width: 48%;justify-content: flex-end;">
            <el-button @click="cancelCourse">取消</el-button>
            <el-button style="background: #00A78E;color: #fff;" @click="saveCourse">保存</el-button>
            <input type="file" @change="uploadRichImgMethod" accept='image/*' ref='uploadRichImg' id="uploadRichImg"
              style="display:none" />

          </div>
        </div>

      </div>
    </el-dialog>

    <el-dialog title="添加老师" :visible.sync="dialogVisibleTeacher" width="30%" :before-close="handleCloseTeacher">
      <div>
        <div
          style="display: flex;align-items: center;font-size: 14px;width: 100px;justify-content: flex-end;margin-bottom: 10px;">
          <div style="width: 4px;height: 13px;border-radius: 50px;background: #00A78E;margin-right: 4px;"></div>
          <div>基础设置</div>
        </div>
        <div style="display: flex;align-items: center;">
          <div style="width: 120px;text-align: right;">讲师名称：</div>
          <el-input v-model="name2" placeholder="请输入讲师名称" style="width: 264px;"></el-input>
        </div>
        <div style="display: flex;align-items: center;margin: 20px 0;">
          <div style="width: 120px;text-align: right;">讲师手机：</div>
          <el-input v-model="phone" placeholder="请输入讲师手机号码" style="width: 264px;"></el-input>
        </div>
        <div style="display: flex;">
          <div style="width: 120px;text-align: right;">简介：</div>
          <el-input type="textarea" :rows="5" placeholder="请输入简介" v-model="des" style="width: 264px;" maxlength="108"
            show-word-limit>
          </el-input>
        </div>
        <div
          style="display: flex;align-items: center;font-size: 14px;width: 100px;justify-content: flex-end;margin:20px 0 10px 0;">
          <div style="width: 4px;height: 13px;border-radius: 50px;background: #00A78E;margin-right: 4px;"></div>
          <div>其他设置</div>
        </div>
        <div style="display: flex;align-items: center;margin: 16px 0;">
          <div style="width: 120px;text-align: right;">性别：</div>
          <div>
            <el-radio-group v-model="sex" text-color="#00A78E">
              <el-radio :label="0">女</el-radio>
              <el-radio :label="1">男</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div style="display: flex;align-items: center;margin: 16px 0;">
          <div style="width: 120px;text-align: right;">生日：</div>
          <div>
            <el-date-picker v-model="birthday" type="date" placeholder="选择日期" value-format="yyyy-MM-dd">
            </el-date-picker>
          </div>
        </div>
        <div style="display: flex;align-items: center;margin: 16px 0;">
          <div style="width: 120px;text-align: right;">讲师照片：</div>
          <img :src="teacherpicUri" alt="" style="width: 104px;height: 104px;" v-if="teacherpicUri">
          <input type="file" @change="uploadTeacher" id='file2' accept='image/*' ref='fileimgs' style="color:#fff"
            v-if="!teacherpicUri" />
          <el-link type="primary" :underline="false" @click="teacherpicUri = ''" v-if="teacherpicUri">删除</el-link>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelTeacher">取 消</el-button>
        <el-button type="primary" style="background: #00A78E;color: #fff;" @click="addTeacher">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="内容管理" :visible.sync="dialogVisibleContent" width="60%" class="dialog1">
      <div style="color: #ACB6B5;display: flex;justify-content: space-between;">
        <div style="display: flex;">
          <div>当前位置：</div>
          <div v-for="item in brandList" v-if="item.id == brandId">{{ item.name }}</div> -
          <div v-for="item in cateGoryList" v-if="item.record.id == recordId">{{ item.record.name }}</div> -
          <div>{{ courseName }}</div>
        </div>
        <div class="hand"
          style="color: #fff;background: #00A78E;width: 96px;height: 32px;line-height: 32px;text-align: center;border-radius: 5px;"
          @click="addContentNew">+添加内容</div>
      </div>
      <div style="display: flex;height: 90%;">
        <!-- <div
          style="padding: 8px 16px;box-shadow: 0px 4px 10px 0px rgba(62, 68, 77, 0.2);height: 100%;width: 144px;border-radius: 10px;">
          <div style="color: #ACB6B5;">课程包目录</div>
          <div style="margin: 16px 0;position: relative;padding: 10px 0;" v-for="(item, index) in tableData" :key="index">
            <div
              style="position: absolute;width: 5px;height: 24px;border-radius: 0px 27px 30px 0px;background: #00A78E;left: -16px;top: 8px;" v-if="courseId==item.record.id">
            </div>
            <div :class="courseId==item.record.id?'selectContent':''" >{{ item.record.name }}</div>
          </div>
          </div> -->
        <div style="width: 100%;">

          <el-table :data="tableData2"
            style="width: 95%;display: flex;flex-direction: column;align-items: flex-end;margin-top: 20px;"
            :header-cell-style="{ background: '#F7F7F7' }">
            <el-table-column prop="name" label="课程包名称">
            </el-table-column>
            <el-table-column prop="weight" label="权重">
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <div>
                  <el-link type="primary" :underline="false" @click="editContent(scope.row)">编辑</el-link>
                  <el-link type="primary" :underline="false" style="margin-left: 20px;"
                    @click="deleteContent(scope.row.id)">删除</el-link>
                  <el-link type="primary" :underline="false" @click="sectionShow(scope.row)"
                    style="margin-left: 20px;">分段介绍</el-link>
                  <el-link type="primary" :underline="false" @click="knowledgeShow(scope.row)"
                    style="margin-left: 20px;">知识点</el-link>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-dialog>

    <el-dialog title="添加内容" :visible.sync="dialogVisibleContentAdd" width="30%" :before-close="closeAddContent">
      <div style="overflow: auto;">
        <!-- <div
          style="display: flex;align-items: center;font-size: 14px;width: 100px;justify-content: flex-end;margin-bottom: 10px;">
          <div style="width: 4px;height: 13px;border-radius: 50px;background: #00A78E;margin-right: 4px;"></div>
          <div>基础设置</div>
          </div> -->
        <div style="display: flex;align-items: center;">
          <div style="width: 80px;text-align: right;">课程名称：</div>
          <el-input v-model="contentName" placeholder="请输入课程名称" style="width: 264px;"></el-input>
        </div>
        <div style="display: flex;align-items: center;margin: 20px 0;">
          <div style="width: 80px;text-align: right;">课程权重：</div>
          <el-input v-model="contentWeight" placeholder="请输入数字" style="width: 264px;"></el-input>
        </div>
        <div style="display: flex;align-items: center;">
          <div style="width: 80px;text-align: right;">视频fileId：</div>
          <el-input v-model="fileId" placeholder="视频fileId：" style="width: 264px;"></el-input>
        </div>
        <div style="display: flex;align-items: center;margin: 20px 0;">
          <div style="width: 80px;text-align: right;">视频url：</div>
          <el-input v-model="videoUri" placeholder="请输入视频链接" style="width: 264px;"></el-input>
        </div>
        <div style="display: flex;align-items: center;margin: 16px 0;">
          <div style="width: 80px;text-align: right;flex-shrink: 0;">视频上传：</div>
          <input type="file" id='filevideo' accept='video/mp4' ref='filevideo' style="color:#fff"
            @change="uploadvideo" />
          <video :src="videoUri" v-if="videoUri" class="avatar" controls="controls" style="width: 351px; height: 198px"
            id="videosrc">
            您的浏览器不支持视频播放
          </video>
          <el-progress type="circle" :percentage="percentage"
            style="width: 351px;height: 198px;justify-content: center;align-items: center;"
            v-if="!videoUri"></el-progress>
        </div>
        <div style="display: flex;margin: 20px 0;flex-direction: column;">
          <div style="width: 80px;text-align: right;">课件设置：</div>
          <quill-editor style="height:200px;width:100%;" v-model="msg2" ref="richAnalysis2" :options="optionsContent"
            @change="quillchange"></quill-editor>
          <input type="file" @change="uploadRichImgMethod2" accept='image/*' ref='uploadRichImg2' id="uploadRichImg2"
            style="display:none" />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeAddContent">取 消</el-button>
        <el-button type="primary" style="background: #00A78E;color: #fff;" @click="confirmContent">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="提示" :visible.sync="dialogVisibleDell" width="30%">
      <span>请确认是否删除</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleDell = false">取 消</el-button>
        <el-button type="primary" @click="deleteC">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="试题管理" :visible.sync="dialogVisibleQuestion" width="60%" class="dialog1">
      <div style="color: #ACB6B5;">
        <div style="display: flex;">
          <div>当前位置：</div>
          <div v-for="item in brandList" v-if="item.id == brandId">{{ item.name }}</div> -
          <div v-for="item in cateGoryList" v-if="item.record.id == recordId">{{ item.record.name }}</div> -
          <div>{{ courseName }}</div>
        </div>
        <div style="display: flex;justify-content: space-between;align-items: center;">
          <div style="display: flex;" class="typeWrap">
            <div v-for="item in levelList" :class="sellevelId == item.id ? 'selectLevel' : ''"
              style="width: 90px;height: 32px;line-height: 32px;text-align: center;" @click="selectQues(item)">
              {{ item.name }}</div>
          </div>
          <div class="hand"
            style="color: #fff;background: #00A78E;width: 96px;height: 32px;line-height: 32px;text-align: center;border-radius: 5px;"
            @click="addQuestion">+添加试题</div>
        </div>
        <div style="display: flex;justify-content: space-between;align-items: center;">
          <div style="display: flex;" class="typeWrap">
            <div v-for="item in questionList" :class="questionId == item.id ? 'selectLevel' : ''"
              style="width: 90px;height: 32px;line-height: 32px;text-align: center;" @click="questionId = item.id">
              {{ item.name }}</div>
          </div>
          <div style="width: 221px;display: flex;">
            <el-input v-model="questionStr" placeholder="请输入搜索内容" class="cinput ac"
              @keyup.enter.native="getQuestionList"></el-input>
            <div style="display: flex;
                  height: 34px;
                  width: 34px;
                  align-items: center;
                  background: #f7f7f7;
                ">
              <img v-if="isshow" src="../../images/search2.png" alt="" @click="getQuestionList"
                style="background: #f7f7f7;width: 25px;height: 25px;" />
              <img v-if="isshow == false" src="../../images/search1.png" alt=""
                style="background: #f7f7f7;width: 25px;height: 25px;" />
            </div>
          </div>
        </div>
      </div>

      <div style="display: flex;height: 90%;">
        <div style="width: 100%;">
          <el-table :header-cell-style="{ background: '#F7F7F7' }" :data="tableData3"
            style="width: 100%; min-height: 500px" @selection-change="handleSelectionChange">
            <el-table-column type="selection" align='center' width="55" />
            <el-table-column type="index" width="50"> </el-table-column>
            <!-- <el-table-column prop="levelName" label="等级" align="center" width="220">
          </el-table-column> -->
            <el-table-column prop="content" align="left" label="题目" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="content" align="left" label="层级" :show-overflow-tooltip="true" width="200">
              <template slot-scope="scope">
                <div>
                  {{
          scope.row.levelId == 1 ? 'lv1' : scope.row.levelId == 2 ? 'lv2' : 'lv3'
        }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="操作" align="center" width="240">
              <template slot-scope="scope">
                <div style="display: flex; justify-content: center">
                  <el-link type="primary" :underline="false" @click='questionDetails(scope.row)'>查看详情</el-link>

                  <el-link type="primary" :underline="false" @click='deleQuestion(scope.row)'
                    style="margin-left:31px">删除</el-link>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <div style="display: flex;align-items: center;justify-content: flex-end;
          ">
          <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="pageIndex" :page-sizes="[10]" :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </span>
    </el-dialog>

    <el-dialog title="添加试题" :visible.sync="dialogVisibleQuestionAdd" width="30%" :before-close="closeAddQuestion">
      <div style="overflow: auto;">
        <div style="display: flex;align-items: center;padding: 10px 0;">
          <div class="line"></div>
          <div style="width: 100px;">题目设置</div>
        </div>
        <div style="display: flex;align-items: center;padding: 10px 0;">
          <div class="line" style="background: #fff;"></div>
          <div style="width: 100px;">层级设置：</div>
          <el-radio v-model="levelId" label="1">lv1</el-radio>
          <el-radio v-model="levelId" label="2">lv2</el-radio>
          <el-radio v-model="levelId" label="3">lv3</el-radio>
        </div>
        <div style="display: flex;align-items: center;padding: 10px 0;">
          <div class="line" style="background: #fff;"></div>
          <div style="width: 100px;">题目类型：</div>
          <el-radio v-model="typeId" label="1">选择题</el-radio>
          <el-radio v-model="typeId" label="2">判断题</el-radio>
        </div>
        <div style="display: flex;align-items: center;padding: 10px 0;">
          <div class="line" style="background: #fff;"></div>
          <div style="width: 100px;text-align: center;">题干：</div>
          <el-input type="textarea" :rows="5" v-model="content">
          </el-input>
        </div>
        <div style="display: flex;align-items: center;padding: 10px 0;">
          <div class="line"></div>
          <div>答案设置(可多选)</div>
        </div>
        <div style="flex-direction: column">
          <el-checkbox-group v-model="checkList" style="display: flex; flex-direction: column"
            v-if="typeId == 1 || typeId == ''">
            <div style="display: flex; margin-top: 26px; align-items: center" v-for="(item, index) of selectData"
              :key="index">
              <el-checkbox :label="item.label" style="display: flex; align-items: center"
                @change="((val) => { sel(val, index) })">
                <div style="display: flex; align-items: center">
                  <div style="margin: 0 13px 0 27px">{{ item.name }}</div>
                  <el-input v-model="item.value" placeholder="请填入选项内容" style="width: 290px"></el-input>
                </div>
              </el-checkbox>
              <img v-if="index + 1 == selectData.length && index > 2" src="../../images/reduce.png" alt=""
                style="width: 22px; height: 23px; margin-left: 10px" @click="deleIndex(index)" />
            </div>
            <div style="margin-top: 12px; margin-left: 200px">
              <img src="../../images/add.png" alt="" style="width: 22px; height: 23px" @click="add"
                v-if='selectData.length < 6' />
            </div>
          </el-checkbox-group>

          <div style="display: flex; margin-top: 26px; flex-direction: column;font-weight: normal"
            v-for="(item, index) of selectData" v-if="typeId == 3" :key="index">
            <div style="align-items:center">
              <div style="margin: 0 13px 0 27px">{{ item.name }}</div>
              <el-input v-model="item.value" placeholder="请填入选项内容" style="width: 290px"></el-input>
              <img v-if="index + 1 == selectData.length && index > 0" src="../../images/reduce.png" alt=""
                style="width: 22px; height: 23px; margin-left: 10px" @click="deleIndex(index)" />
            </div>
            <div style="margin-top: 12px; margin-left: 200px" v-if="index + 1 == selectData.length && index < 5">
              <img src="../../images/add.png" alt="" style="width: 22px; height: 23px" @click="add" />
            </div>
          </div>
          <div style="display: flex; margin-top: 26px; align-items: center" v-for="(item, index) of selectData"
            v-if="typeId == 2" :key="index">
            <el-radio v-model="radioAnsWer" :label="item.label" style="display: flex; align-items: center">
              <div style="margin: 0 13px 0 27px">{{ item.name }}</div>
            </el-radio>
            <img v-if="index + 1 == selectData.length && index > 2" src="../../images/reduce.png" alt=""
              style="width: 22px; height: 23px" @click="deleIndex(index)" />
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeAddQuestion">取 消</el-button>
        <el-button type="primary" style="background: #00A78E;color: #fff;" @click="submit">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog :title="sectionType==1?'知识点':'分段介绍'" :visible.sync="isSection" width="50%" :before-close="handleCloseSection" top="0" class="mywrap">
      <el-table :data="sectionList" height="100%"  style="width: 100%">
        <el-table-column prop="timeStr" label="分段时间" width="180" v-if="sectionType==2">
        </el-table-column>
        <el-table-column prop="title" label="标题" width="180">
        </el-table-column>
        <el-table-column prop="content" label="简介">
        </el-table-column>
        <el-table-column label="操作" align="center" width="100">
          <template slot-scope="scope">
            <div style="display: flex; justify-content: center;flex-direction:column;">
              <el-link type="primary" :underline="false" @click='EditSectionBtn(scope.row)'>编辑分段</el-link>
              <!-- <el-link type="primary" :underline="false" @click='deleQuestion(scope.row)'>删除</el-link> -->
            </div>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloseSection">取消</el-button>
        <el-button style="background: #00A78E;color: #fff;" @click='addSectionBtn'>添加分段</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="sectionType==1?'添加分段':'添加知识点'" :visible.sync="isSectionEdit" width="30%" :before-close="handleCloseSectionEdit" top="0" class="mywrapAdd">
      <div>
        <div style="display: flex;align-items: center;margin: 10px 0;" v-if="sectionType==2">
          <div style="width:80px;flex-shrink: 0">分段时间</div><el-input v-model="timeStr" placeholder="请输入内容"></el-input>
        </div>
        <div style="display: flex;align-items: center;margin: 10px 0;">
          <div style="width:80px;flex-shrink: 0">标题</div><el-input v-model="sectionTitle" placeholder="请输入内容"></el-input>
        </div>
        <div style="display: flex;align-items: center;margin: 10px 0;">
          <div style="width:80px;flex-shrink: 0">简介</div><el-input v-model="sectionContent" placeholder="请输入内容" type="textarea" rows="10"></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloseSectionEdit">取消</el-button>
        <el-button style="background: #00A78E;color: #fff;" @click="operateSection">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
var COS = require('cos-js-sdk-v5');
var cos = new COS({
  getAuthorization: function (options, callback) {
    getCosCredit().then(result => {
      var data = result.data.data;
      var credentials = data && data.credentials;
      if (!data || !credentials) return console.error('credentials invalid');
      sessionStorage.setItem('bucket', data.bucket)
      callback({
        TmpSecretId: credentials.tmpSecretId,
        TmpSecretKey: credentials.tmpSecretKey,
        XCosSecurityToken: credentials.sessionToken,
        // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
        StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
        ExpiredTime: data.expiredTime, // 时间戳，单位秒，如：1580000900
      });
    })
  }
});
const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
  ['blockquote', 'code-block'],

  [{ 'header': 1 }, { 'header': 2 }],               // custom button values
  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
  [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
  [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
  [{ 'direction': 'rtl' }],                         // text direction

  [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

  [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
  [{ 'font': [] }],
  [{ 'align': [] }],
  ['link', 'image', 'video'],
  ['clean']                                         // remove formatting button
]
import TcVod from 'vod-js-sdk-v6'
import uploadImg from '../../utils/uploadImg'
import uploadRIchImg from '../../utils/uploadRIchImg'
import uploadRichText from '../../utils/uploadRichText'
import { saveOrUpdatesection,sectionListMethod,qssavedel, qssaveOrUpdate2, qssaveOrUpdate, qsList, dellmedia, contentEdit, courseEdit, contentList, courseDele, contentDelet, courseInfoList, levelList, getCosCredit, contentAdd, currentCourseList, courseDeleteAll, teacherList, teacherAdd, courseAdd, courseClassList, courseList, getRichText, uploadSignature, questionList } from '../../apis/index'
export default {
  data() {
    return {
      //添加分段数据
      sectionContent:'',
      timeStr:'',
      sectionTitle:'',
      sectionType:1,//1分段 0知识点
      sectionContentId:'',
      sectionCourseId:'',
      isSnowledge: false,//是否显示知识点
      isSection: false,//是否显示分段
      isSectionEdit:false,//分段介绍添加窗口
      sectionList: [],//分段列表
      sectionId:'',//编辑分段/知识点用
      
      durationMillisecond: '',//视频时长
      tableData3: [],
      // tagList: ['层级一', '层级二', '层级三'],
      tagList: [
        {
          value:1,
          name:'美学初探'
        },
        {
          value:2,
          name:'美丽进阶'
        },
        {
          value:3,
          name:'技能成长'
        },
        {
          value:4,
          name:'专家之路'
        },
      ],
      tag: '',
      editQuestionId: '',//编辑试题的id
      checkList: [],
      radioLevel: '1',//添加试题的层级
      typeId: '1',//题目类型
      content: '',//题干
      radioAnsWer: '',//判断题选择
      selectData: [
        {
          label: "1",
          name: "A",
          value: "",
        },
        {
          label: "2",
          name: "B",
          value: "",
        },
        {
          label: "3",
          name: "C",
          value: "",
        },
      ],
      dialogVisibleQuestion: false,//试题管理
      dialogVisibleQuestionAdd: false,//添加试题框
      questionStr: '',//搜索题目关键字
      levelList: [//层级列表
        {
          name: '全部',
          id: ''
        },
        {
          name: 'lv1',
          id: 1
        },
        {
          name: 'lv2',
          id: 2
        },
        {
          name: 'lv3',
          id: 3
        },
      ],
      levelId: '1',//层级id
      sellevelId: '',//筛选用的
      questionList: [//问题类型列表
        {
          name: '全部',
          id: ''
        },
        {
          name: '选择题',
          id: 1
        },
        {
          name: '判断题',
          id: 2
        }
      ],
      questionId: '',//问题类型id
      isshow: false,
      uploader: '',
      fileId: '',//视频标识
      coursePageName: '',//搜索输入的课程包名称
      courseName: '',//当前课程包名称
      priceMoney: '',
      pricePoint: '',
      contentId: '',//内容id
      contentName: '',//内容名称
      contentWeight: '',//内容权重
      percentage: 0,//视频上传进度
      videoUri: '',//视频uri    https://sihuankangyuan.cn/content/resource/newManage/video/44132e0c68b8f79975c84d66e270f73a1662426568160.mp4
      teacherMain: '',
      msg2: '',
      dialogVisibleDell: false,
      courseId: '',
      dropdownList: [
        // {
        //   name: '详情',
        //   value: 'a'
        // },
        {
          name: '编辑',
          value: 'b'
        },
        {
          name: '删除',
          value: 'c'
        }
      ],
      courseTitleInd: 0,
      configState: 0,
      configType: '',
      listTeacher: '',
      tableData: [],//课程包列表
      recordId: '',//右边标签标示id
      cateGoryList: [],
      homeItem: {
        picUri: '',
        isValidity: false,
        id: ''
      },
      experience: "",//经验
      point: '',//积分
      msg: '',
      configValue1: '',//付费设置
      configValue2: false,//是否需要考试
      weight: '',
      categoryId: '',//分类id
      listTeacher: [],//讲师列表
      teacherUserId: '',//讲师id
      birthday: '',//讲师生日
      des: '',//讲师简介
      name: '',
      name2: '',//讲师名称
      phone: '',//讲师手机
      teacherpicUri: '',//讲师照片
      sex: '',//讲师性别
      picUri: '',//课程包封面
      dialogVisibleTeacher: false,//添加导师弹窗
      tableData2: [],
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisibleContent: false,//内容管理
      dialogVisibleContentAdd: false,//添加内容
      statusList: ['全部', '会员', '付费', '免费'],
      brandId: 1,
      brandList: [],
      levelLists: [],
      leveId: 1,
      total: 0,
      pageIndex: 1,
      pageSize: 10,
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
      ],
      value: "",
      isshow: false,
      isshow2: true,
      isdisabled: true,
      currentPage: 1,
      radio: "4",
      input: "",

      options2: {
        theme: "snow",
        placeholder: "请输入内容",
        modules: {
          toolbar: {
            container: toolbarOptions,  // 工具栏
            handlers: {
              'image': function (value) {
                if (value) {
                  document.getElementById('uploadRichImg').click()
                } else {
                  this.quill.format('image', false);
                }
              }
            }
          }
        }
      },
      optionsContent: {
        theme: "snow",
        placeholder: "请输入内容",
        modules: {
          toolbar: {
            container: toolbarOptions,  // 工具栏
            handlers: {
              'image': function (value) {
                if (value) {
                  document.getElementById('uploadRichImg2').click()
                } else {
                  this.quill.format('image', false);
                }
              }
            }
          }
        }
      },
      
    };
  },
  watch: {
    typeId(newdata, olddate) {//添加试题类型监听
      console.log(newdata);
      if (newdata == 1 && !this.editQuestionId) {
        this.selectData = [
          {
            label: "1",
            name: "A",
            value: "",
          },
          {
            label: "2",
            name: "B",
            value: "",
          },
          {
            label: "3",
            name: "C",
            value: "",
          },
        ];
      } else if (newdata == 2) {
        this.selectData = [
          {
            label: "1",
            name: "正确",
          },
          {
            label: "2",
            name: "错误",
          },
        ];
      }
    },
    coursePageName(newdata, olddata) {
      if (newdata == '') {
        this.getcourseList()
      } else {
        let tableData = this.tableData
        let tableDataNew = tableData.filter(item => {
          return item.record.name.indexOf(newdata) != -1
        })
        this.tableData = tableDataNew
      }
    },
    brandId() {
      this.getCateGorylist().then(res => {
        this.recordId = this.categoryId = this.cateGoryList[0].record.id
        this.getcourseList()
      })
    },
    configValue1(newdata, olddata) {
      if (newdata != 4) {
        this.priceMoney = this.pricePoint = 0
      }

    },
    input(newdata, olddata) {
      console.log(newdata);
      if (newdata) {
        this.isshow = true;
      } else {
        this.input = ''
        this.list()
        this.isshow = false;
      }
    },
    questionStr(newdata, olddata) {
      this.pageIndex = 1
      console.log(newdata);
      if (newdata) {
        this.isshow = true;
      } else {
        this.questionStr = ''
        this.getQuestionList()
        this.isshow = false;
      }
    },
    dialogVisibleQuestion(newdata) {//监听试题管理关闭重置字段
      if (!newdata) {
        this.questionStr = ''
        this.questionId = ''
      }
    },
    questionId(newdata, olddata) {//监听切换题目类型
      this.questionId = newdata
      this.pageIndex = 1
      this.getQuestionList()
      // if (newdata == "2") {
      //   this.isshow2 = true;
      // } else {
      //   this.isshow2 = false;
      // }
    },
  },
  created() {
    this.brandList = this.$store.state.brandList
    let categoryId = this.$route.query.id
    let brandId = this.$route.query.brandId
    this.recordId = this.categoryId = categoryId
    this.brandId = Number(brandId)
    // this.getcontentList()
    this.getTeacherList()
    this.getCateGorylist()
    this.getcourseList()
    // this.getUploadSignature()
  },
  //     this.leave().then(res => {
  //       this.list()
  //     })
  //   },
  //   beforeRouteLeave(to, from, next) {
  //   if(to.fullPath=='/videoAdd' || to.fullPath=='/contentAdd'){//添加页，就不缓存
  //     this.$route.meta.keepAlive=false
  //     setTimeout(() => {
  //       next()
  //     }, 100);
  //   }else{//详情页缓存
  //   console.log('我是详情缓存')
  //    this.$route.meta.keepAlive=true
  //     setTimeout(() => {
  //       next()
  //     }, 100);
  //   }
  //   // 导航离开该组件的对应路由时调用
  //   // 可以访问组件实例 `this`
  // },
  methods: {
    addSectionBtn(){//添加分段/知识点按钮
      this.isSectionEdit=true
      this.resetSection()
    },
    EditSectionBtn(row){//编辑分段/知识点按钮
      console.log(row)
      this.isSectionEdit=true
      this.sectionContentId=row.contentId,
      this.sectionCourseId=row.courseId,
      this.sectionContent=row.content,
      this.timeStr=row.timeStr
        // startTime:this.sectionType==0?'':this.timeStr,
      this.sectionTitle=row.title,
      this.sectionType=row.type//1分段 0知识点
      this.sectionId=row.id
    },
   async operateSection(){//添加编辑分段/知识点
      const params={
        contentId:this.sectionContentId,
        courseId:this.sectionCourseId,
        content:this.sectionContent,
        timeStr:this.sectionType==0?'':this.timeStr,
        title:this.sectionTitle,
        type:this.sectionType,//1分段 0知识点
      }
      if(this.sectionId){
        params.id=this.sectionId
      }
      console.log(params)
     const {data}=await saveOrUpdatesection(params)
     if(data.code==200){
      // this.handleCloseSection()
      this.handleCloseSectionEdit()
      this.getSelectionList()
     }else{
      this.$message({
          type: 'error',
          message: data.msg
        });
     }
    },
   async getSelectionList(){//获取分段/知识点列表
    const params={
      contentId:this.sectionContentId,
      courseId:this.sectionCourseId,
    }
    const {data}=await sectionListMethod(params)
    if(data.code==200){
      if(data.data && this.sectionType==2){//分段
        this.sectionList=data.data.filter(item=>{return item.type==2})
      }
      if(data.data && this.sectionType==1){//知识点
        this.sectionList=data.data.filter(item=>{return item.type==1})
      }
      if(!data.data){
        this.sectionList=[]
      }
      
     }else{
      this.$message({
          type: 'error',
          message: data.msg
        });
     }
    },
    sectionShow(row) {//显示分段
      this.isSection = true
      this.sectionType=2
      this.sectionContentId=row.id
      this.sectionCourseId=row.courseId
      this.getSelectionList()
    },
    knowledgeShow(row) {//知识点
      this.isSection = true
      this.sectionType=1
      this.sectionContentId=row.id
      this.sectionCourseId=row.courseId
      this.getSelectionList()
    },
    handleCloseSection(){//关闭添加列表窗口
      this.isSection = false
      this.resetSection()
    },
    handleCloseSectionEdit(){//关闭添加窗口
      this.isSectionEdit = false
      this.resetSection()
    },
    resetSection(){//重置分段添加数据
      // this.sectionContentId=''
      // this.sectionCourseId=''
      this.sectionContent=''
      this.timeStr=''
      this.sectionTitle=''
      this.sectionId=''
    },
    
    selectQues(item) {
      this.sellevelId = item.id
      this.getQuestionList()
    },
    deleQuestion(row) {
      const params = {
        id: row.id,
        courseId: this.courseId
      }
      this.$confirm('此操作将永久删除该项, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        qssavedel(params).then(res => {
          if (res.data.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getQuestionList()
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            });
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    sel(val, index) {
      this.selectData[index].checked = val
      let obj = {
        checked: val,
        label: this.selectData[index].label,
        name: this.selectData[index].name,
        value: this.selectData[index].value,
      }
      this.$set(this.selectData, index, obj)
    },
    questionDetails(details) {//查看试题详情
      this.dialogVisibleQuestionAdd = true
      console.log(details)
      this.courseId = details.courseId
      if (details.typeId == 1) {//选择题
        this.typeId = String(details.typeId)
        // this.levelId=details.levelId
        this.content = details.content
        let answer = details.answer
        let arr = answer.split('&&&')
        let obj = arr.map((item, index) => {
          return {
            label: index,
            name: item.slice(0, 1),
            value: item.slice(1)
          }
        })
        this.selectData = obj
        let rightKey = details.rightKey
        let arr2 = rightKey.split('')
        let selectData = this.selectData
        arr2.map((item, index) => {
          selectData.forEach((item2, index2) => {
            if (index == index2 && item == 1) {
              item2.checked = true
            }
          })
        })
        this.editQuestionId = details.id
        this.selectData = selectData
        this.checkList = selectData.filter(item => item.checked).map(item => item.label)
        console.log(selectData)
      } else if (details.typeId == 2) {//判断题
        this.typeId = String(details.typeId)
        // this.levelId=details.levelId
        this.content = details.content

        this.selectData = this.selectData = [
          {
            label: "1",
            name: "正确",
          },
          {
            label: "2",
            name: "错误",
          },
        ];
        let selectData = this.selectData
        this.editQuestionId = details.id
        let rightKey = details.rightKey
        let arr2 = rightKey.split('')
        arr2.map((item, index) => {
          selectData.forEach((item2, index2) => {
            if (index == index2 && item == 1) {
              this.radioAnsWer = item2.label
            }
          })
        })
      }

    },
    async submit() {
      if (!this.editQuestionId) {//添加
        if (this.typeId == 1) {//选择题
          let selectData = this.selectData
          let checkList = this.checkList
          selectData.forEach(item => {
            checkList.forEach(item2 => {
              if (item.label == item2) {
                item.code = 1
              }
            })
          })
          let str = ''
          const titles = selectData.map(item => {
            if (item.code) {
              str += item.code
            } else {
              str += 0
            }
            return item.name + item.value
          })
          let len = 8 - str.length
          for (var i = 0; i < len; i++) {
            str += '0'
          }
          const title = titles.join('&&&')
          const params = {
            typeId: this.typeId,
            content: this.content,
            rightKey: str,
            answer: title,
            courseId: this.courseId,
            levelId: this.levelId
          }
          // console.log(params)
          // return
          const { data } = await qssaveOrUpdate(params)
          if (data.code == 200) {
            this.$message.success('操作成功')
            this.dialogVisibleQuestionAdd = false
            this.getQuestionList()
          } else {
            this.$message.error(data.msg)
          }
        } else if (this.typeId == 2) {//判断题
          let selectData = this.selectData
          selectData.forEach(item => {

            if (item.label == this.radioAnsWer) {
              item.code = 1
            }

          })
          let str = ''
          const titles = selectData.map(item => {
            if (item.code) {
              str += item.code
            } else {
              str += 0
            }
            return item.name + item.value
          })
          let len = 8 - str.length
          for (var i = 0; i < len; i++) {
            str += '0'
          }
          const title = titles.join('&&&')
          const params = {
            typeId: this.typeId,
            content: this.content,
            rightKey: str,
            courseId: this.courseId,
            levelId: this.levelId
          }
          // console.log(params)
          // return
          const { data } = await qssaveOrUpdate(params)
          if (data.code == 200) {
            this.$message.success('操作成功')
            this.dialogVisibleQuestionAdd = false
            this.getQuestionList()
          } else {
            this.$message.error(data.msg)
          }
        }
      } else {//修改
        if (this.typeId == 1) {//选择题
          let selectData = this.selectData
          let checkList = this.checkList
          selectData.forEach(item => {
            checkList.forEach(item2 => {
              if (item.label == item2) {
                item.code = 1
              }
            })
          })
          let str = ''
          const titles = selectData.map(item => {
            if (item.code) {
              str += item.code
            } else {
              str += 0
            }
            return item.name + item.value
          })
          let len = 8 - str.length
          for (var i = 0; i < len; i++) {
            str += '0'
          }
          const title = titles.join('&&&')
          const params = {
            typeId: this.typeId,
            content: this.content,
            rightKey: str,
            answer: title,
            courseId: this.courseId,
            id: this.editQuestionId,
            levelId: this.levelId
          }
          // console.log(params)
          // return
          const { data } = await qssaveOrUpdate2(params)
          if (data.code == 200) {
            this.$message.success('操作成功')
            this.dialogVisibleQuestionAdd = false
            this.getQuestionList()
          } else {
            this.$message.error(data.msg)
          }
        } else if (this.typeId == 2) {//判断题
          let selectData = this.selectData
          selectData.forEach(item => {

            if (item.label == this.radioAnsWer) {
              item.code = 1
            }

          })
          let str = ''
          const titles = selectData.map(item => {
            if (item.code) {
              str += item.code
            } else {
              str += 0
            }
            return item.name + item.value
          })
          let len = 8 - str.length
          for (var i = 0; i < len; i++) {
            str += '0'
          }
          const title = titles.join('&&&')
          const params = {
            typeId: this.typeId,
            content: this.content,
            rightKey: str,
            courseId: this.courseId,
            id: this.editQuestionId,
            levelId: this.levelId
          }
          // console.log(params)
          // return
          const { data } = await qssaveOrUpdate2(params)
          if (data.code == 200) {
            this.$message.success('操作成功')
            this.dialogVisibleQuestionAdd = false
            this.getQuestionList()
          } else {
            this.$message.error(data.msg)
          }
        }
      }

    },
    add() {
      if (!this.typeId) {
        this.$message.error('请先选择题目类型')
        return
      }
      //添加
      if (this.typeId == 1) {
        let selectData = this.selectData;
        let res = selectData.some((item) => {
          return item.name == "D";
        });
        if (res) {
          let res = selectData.some((item) => {
            return item.name == "E";
          });
          if (res) {
            let res = selectData.some((item) => {
              return item.name == "E";
            });
            if (res) {
              let res = selectData.some((item) => {
                return item.name == "F";
              });
              if (!res) {
                this.selectData.push({
                  label: "6",
                  name: "F",
                  value: "",
                });
              }
            }
          } else {
            this.selectData.push({
              label: "5",
              name: "E",
              value: "",
            });

            console.log(this.selectData);
          }
        } else {
          this.selectData.push({
            label: "4",
            name: "D",
            value: "",
          });
          console.log(this.selectData);
        }
      }
    },
    deleIndex(ind) {
      this.selectData.splice(ind, 1);
    },
    handleCurrentChange(val) {
      this.pageIndex = val
      this.getQuestionList()
    },
    openQuestionList(row) {//打开试题列表
      this.dialogVisibleQuestion = true
      this.courseId = row.record.id
      this.courseName = row.record.name
      this.getQuestionList()
    },
    async getQuestionList() {//获取试题列表
      const params = {
        questionTypeId: this.questionId,
        searchStr: this.questionStr,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        levelId: this.sellevelId,
        brandId: this.brandId,
        courseId: this.courseId
      }
      const { data } = await qsList(params)
      if (data.code == 200) {
        this.tableData3 = data.data.records
        this.total = Number(data.data.total)
      }
    },
    async getUploadSignature() {//获取云点播上传凭证
      const { data } = await uploadSignature()
      if (data.code == 200) {
        sessionStorage.setItem('getUploadSignature', data.data.getUploadSignature)
        return data.data.getUploadSignature
      }
      console.log(data.data.getUploadSignature)
    },
    closeAddContent() {
      console.log('我是取消')
      this.dialogVisibleContentAdd = false
      if (this.percentage && this.percentage !== 100) {
        this.uploader.cancel()
        this.$message.error('已取消上传!')
        this.$refs["filevideo"].value = ''
        this.fileId = ''
        this.videoUri = ''
        return
      } else if (this.fileId && !this.contentId) {//上传完视频点击了取消，删除云点播里面的资源
        dellmedia({ fileId: this.fileId }).then(res => {
          if (res.data.code == 200) {
            this.fileId = ''
          }
        })
      } else {
        // this.uploader.cancel()
        this.$refs["filevideo"].value = ''
      }

    },
    addteac() {
      this.dialogVisibleTeacher = true
    },
    uploadTeacher() {//上传讲师封面
      if (this.$refs['fileimgs'].files[0]) {
        let selectFileobj = this.$refs['fileimgs'].files[0]
        uploadImg(selectFileobj, (res) => {
          console.log(res)
          this.teacherpicUri = res
        })
      }
    },
    cancelCourse() {//取消添加课程包
      this.dialogVisible = false
      this.configValue1 = ''
    },
    uploadvideo(e) {
      let that = this
      if (this.percentage && this.percentage !== 100) {
        this.$message.error('有上传任务!')
        return
      }
      //上传视频
      if (this.$refs["filevideo"].files[0]) {
        // let filename = this.$refs["filevideo"].files[0].name;
        let selectFileobj = this.$refs["filevideo"].files[0];

        const url = URL.createObjectURL(selectFileobj);
        const filelement = new Audio(url);
        filelement.addEventListener("loadedmetadata", function () {
          this.durationMillisecond = filelement.duration * 1000 //获取视频时长
          console.log(this.durationMillisecond)
          sessionStorage.setItem('durationMillisecond', this.durationMillisecond)
          console.log(selectFileobj);
          this.videoUri = ''
          that.uploadYdb(selectFileobj)//腾讯云云点播上传视频
        })


        // console.log(selectFileobj);
        // this.videoUri = ''
        // this.uploadYdb(selectFileobj)//腾讯云云点播上传视频


        // this.uploadvideo2(selectFileobj)//腾讯云对象存储上传视频
      }
    },
    uploadYdb(selectFileobj) {//腾讯云云点播上传视频
      let that = this
      let getVideoSignature = () => {
        return that.getUploadSignature()
      }
      const tcVod = new that.$TcVod({
        getSignature: getVideoSignature // 前文中所述的获取上传签名的函数
      })
      const uploader = tcVod.upload({
        mediaFile: selectFileobj, // 媒体文件（视频或音频或图片），类型为 File
      })
      that.uploader = uploader
      sessionStorage.setItem('selectFileobjData', selectFileobj)
      uploader.on('media_progress', function (info) {
        console.log(info.percent) // 进度
        that.percentage = parseInt(info.percent * 100);
      })
      uploader.done().then(function (res) {
        console.log(res)
        let fileId = res.fileId
        that.videoUri = res.video.url
        that.fileId = fileId
        sessionStorage.setItem('videoUri', res.video.url)
        that.$refs["filevideo"].value = ''
        setTimeout(() => {
          that.$message({
            message: `上传成功，视频正在转码请稍后再播放`,
            type: 'warning',
          });
        }, 1000)
        // deal with doneResult
      }).catch(function (err) {
        // deal with error
      })
    },

    uploadvideo2(selectFileobj) {//腾讯云对象存储上传视频
      let that = this;
      cos.options.getAuthorization("", function (obj) {
        let name = selectFileobj.name.replace(/MP4/g, "mp4");

        let filename = 'content/resource/newManage/video/' +
          name.slice(0, name.length - 4) +
          new Date().getTime() +
          name.slice(-4);
        console.log(filename)
        cos.sliceUploadFile(
          {
            Bucket: sessionStorage.getItem("bucket") /* 必须 */,
            Region: "ap-nanjing" /* 存储桶所在地域，必须字段 */,
            Key: filename /* 必须 */,
            Body: selectFileobj /* 必须 */,
            onTaskReady: function (taskId) {
              /* 非必须 */
              console.log(taskId);
              that.taskId = taskId
            },
            onHashProgress: function (progressData) {
              /* 非必须 */
              console.log(JSON.stringify(progressData));
            },
            onProgress: function (progressData) {
              /* 非必须 */
              console.log(JSON.stringify(progressData));
              that.percentage = parseInt(progressData.percent * 100);
            },
          },
          function (err, data) {
            console.log(err || data);
            if (data) {
              let Location = data.Location.replace(/content-1308336073.cos.ap-nanjing.myqcloud.com/g, 'https://sihuankangyuan.cn')
              that.videoUri = Location
              sessionStorage.setItem('videoUri', Location)
              setTimeout(() => {
                that.$message({
                  message: `上传成功，视频正在转码请稍后再播放`,
                  type: 'warning',
                });
              }, 1000)
            }
          }
        );
      });
    },
    async getCateGorylist() {//获取类列表
      const params = {
        brandId: this.brandId
      }
      const { data } = await courseClassList(params)
      if (data.code == 200) {
        this.cateGoryList = data.data
      }
    },
    courseTitle(index) {//点击切换课程类型
      if (index == 0) {
        this.courseTitleInd = 0,
          this.configState = this.configType = ''
      } else if (index == 1) {
        this.courseTitleInd = 1,
          this.configState = 2
        this.configType = 2
      } else if (index == 2) {
        this.courseTitleInd = 2,
          this.configState = 4
        this.configType = 4
      } else if (index == 3) {
        this.courseTitleInd = 3,
          this.configState = 0
        this.configType = 6
      }
      this.getcourseList()
    },
    async getcourseList() {//获取课程包列表
      const params = {
        brandId: this.brandId,
        categoryId: this.categoryId,
        configState: this.configState,
        configType: this.configType,
        teacherUserId: this.teacherMain
      }
      const { data } = await courseList(params)
      if (data.code == 200) {
        this.tableData = data.data
      }
    },
    async getcontentList() {//获取内容列表
      const params = {
        brandId: this.brandId,
        courseId: this.courseId,
      }
      const { data } = await contentList(params)
      if (data.code == 200) {
        this.tableData2 = data.data
      }
    },
    uploadRichImgMethod(e) {//上传图片至富文本
      if (this.$refs['uploadRichImg'].files[0]) {
        let selectFileobj = this.$refs['uploadRichImg'].files[0]
        let quill = this.$refs.richAnalysis.quill
        uploadRIchImg(selectFileobj, quill)
      }
    },
    uploadRichImgMethod2(e) {//内容部分上传图片至富文本
      if (this.$refs['uploadRichImg2'].files[0]) {
        let selectFileobj = this.$refs['uploadRichImg2'].files[0]
        let quill = this.$refs.richAnalysis2.quill
        uploadRIchImg(selectFileobj, quill)
      }
    },
    async getTeacherList() {//获取讲师列表
      const { data } = await teacherList()
      if (data.code == 200) {
        this.listTeacher = data.data
      }
    },
    cancelTeacher() {//取消导师按钮
      this.birthday = ''
      this.des = ''
      this.name2 = ''
      this.phone = ''
      this.teacherpicUri = ''
      this.sex = ''
      this.dialogVisibleTeacher = false
    },
    async addTeacher() {//添加导师
      const params = {
        birthday: this.birthday,
        des: this.des,
        name: this.name2,
        phone: this.phone,
        picUri: this.teacherpicUri,
        sex: this.sex
      }
      const { data } = await teacherAdd(params)
      if (data.code == 200) {
        this.$message({
          type: 'success',
          message: '添加成功!'
        });
        this.dialogVisibleTeacher = false
        this.birthday = ''
        this.des = ''
        this.name2 = ''
        this.phone = ''
        this.teacherpicUri = ''
        this.sex = ''
        this.getTeacherList()
      }
    },
    handleCloseTeacher() {//关闭讲师添加弹窗
      this.birthday = ''
      this.des = ''
      this.name2 = ''
      this.phone = ''
      this.teacherpicUri = ''
      this.sex = ''
      this.dialogVisibleTeacher = false
    },
    addCourseTag() {//添加课程包按钮
      this.dialogVisible = true
      this.des = ''
      this.name = ''
      this.picUri = ''
      this.teacherUserId = ''
      this.weight = ''
      this.point = ''
      this.experience = ''
      this.homeItem = {}
      this.configValue = ''
      this.configValue1 = ''
      this.configValue2 = false
      this.desUri = ''
      this.msg = ''
      this.courseId = ''
      this.priceMoney = '',
        this.pricePoint = ''
      this.tag = ''

    },
    addQuestion() {//添加试题
      this.dialogVisibleQuestionAdd = true
      this.editQuestionId = ''
      this.leveId = '1'
      this.typeId = '1'
      this.content = ''
      this.selectData = [
        {
          label: "1",
          name: "A",
          value: "",
        },
        {
          label: "2",
          name: "B",
          value: "",
        },
        {
          label: "3",
          name: "C",
          value: "",
        },
      ];
    },
    closeAddQuestion() {//关闭试题
      this.dialogVisibleQuestionAdd = false
    },
    editContent(row) {//编辑内容
      this.dialogVisibleContentAdd = true
      this.contentId = row.id
      this.contentName = row.name
      this.contentWeight = row.weight
      this.videoUri = row.videoUri
      this.fileId = row.fileId
      getRichText(row.resourceUri).then(res => {
        this.msg2 = res.data
      })
    },
    addContentNew() {//添加内容
      this.dialogVisibleContentAdd = true
      this.contentId = ''
      this.contentName = ''
      this.contentWeight = ''
      this.videoUri = ''
      this.msg2 = ''
      this.percentage = 0

    },
    confirmContent() {
      if (!this.contentId) {//保存内容
        uploadRichText(this.msg2, (desUri) => {
          const params = {
            name: this.contentName,
            weight: this.contentWeight,
            videoUri: this.videoUri,
            resourceUri: desUri,
            brandId: this.brandId,
            courseId: this.courseId,
            fileId: this.fileId,
            // durationMillisecond:this.durationMillisecond
            durationMillisecond: Number(sessionStorage.getItem('durationMillisecond'))
          }
          contentAdd(params).then(res => {
            if (res.data.code == 200) {
              this.$message.success('添加成功')
              this.getcontentList()
              this.dialogVisibleContentAdd = false
              this.fileId = ''
              this.videoUri = ''
              sessionStorage.setItem('durationMillisecond', '')
            }
          })
          console.log(params)
        })
      } else {//更新
        uploadRichText(this.msg2, (desUri) => {
          const params = {
            name: this.contentName,
            weight: this.contentWeight,
            videoUri: this.videoUri,
            resourceUri: desUri,
            brandId: this.brandId,
            courseId: this.courseId,
            id: this.contentId,
            fileId: this.fileId
          }
          contentEdit(params).then(res => {
            if (res.data.code == 200) {
              this.$message.success('添加成功')
              this.getcontentList()
              this.dialogVisibleContentAdd = false
              this.fileId = ''
              this.videoUri = ''
            }
          })
          console.log(params)
        })
      }

    },
    deleteContent(id) {//删除内容
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = {
          brandId: this.brandId,
          contentId: id
        }
        contentDelet(params).then(res => {
          if (res.data.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getcontentList()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    saveCourse() {//保存课程包
      if (this.courseId) {//更新
        uploadRichText(this.msg, (desUri) => {
          const params = {
            brandId: this.brandId,
            categoryId: this.categoryId,
            des: this.des,
            name: this.name,
            picUri: this.picUri,
            teacherUserId: this.teacherUserId,
            weight: this.weight,
            point: this.point,
            experience: this.experience,
            id: this.courseId,
            homeItem: {
              picUri: this.homeItem.picUri,
              isValidity: this.homeItem.isValidity ? 1 : 0,
              id: this.homeItem.id
            },
            configValue: (this.configValue2 ? 1 : 0) + this.configValue1,
            desUri,
            priceMoney: Number(this.priceMoney) * 100,
            pricePoint: Number(this.pricePoint),
            levelId: this.tag
          }
          courseEdit(params).then(res => {
            if (res.data.code == 200) {
              this.$message.success('更新成功')
              this.getcourseList()
              this.dialogVisible = false
              this.configValue1 = ''
            }
          })
          console.log(params)
        })
      } else {//添加
        uploadRichText(this.msg, (desUri) => {
          const params = {
            brandId: this.brandId,
            categoryId: this.categoryId,
            des: this.des,
            name: this.name,
            picUri: this.picUri,
            teacherUserId: this.teacherUserId,
            weight: this.weight,
            point: this.point,
            experience: this.experience,
            homeItem: {
              picUri: this.homeItem.picUri,
              isValidity: this.homeItem.isValidity ? 1 : 0,
              id: this.homeItem.id
            },
            configValue: (this.configValue2 ? 1 : 0) + this.configValue1,
            desUri,
            priceMoney: Number(this.priceMoney) * 100,
            pricePoint: Number(this.pricePoint),
            levelId: this.tag
          }
          courseAdd(params).then(res => {
            if (res.data.code == 200) {
              this.$message.success('添加成功')
              this.getcourseList()
              this.dialogVisible = false
              this.configValue1 = ''
            }
          })
          console.log(params)
        })
      }

    },
    handleCommand(command) {
      console.log(command)
      if (command.type == 'a') {//详情

      } else if (command.type == 'b') {//编辑

        this.dialogVisible = true
        let row = command.row
        console.log(row.record.configValue)

        this.categoryId = row.record.categoryId
        this.des = row.record.des
        this.name = row.record.name
        this.picUri = row.record.picUri
        this.teacherUserId = row.record.teacherUserId
        this.weight = row.record.weight
        this.point = row.record.point
        this.experience = row.record.experience
        this.priceMoney = row.record.priceMoney / 100
        this.pricePoint = row.record.pricePoint
        this.courseId = row.record.id
        this.tag = row.record.levelId
        this.homeItem = row.homeItem ? {
          picUri: row.homeItem.picUri,
          isValidity: row.homeItem.isValidity == 1 ? true : false,
          id: row.homeItem.id
        } : {
          picUri: '',
          isValidity: false,
          id: ''
        }
        // this.homeItem = {
        //   picUri: row.homeItem.picUri,
        //   isValidity: row.homeItem.isValidity == 1 ? true : false,
        //   id: row.homeItem.id
        // }
        if ((row.record.configValue & 2) == 2) {
          this.configValue1 = 2
        } else if ((row.record.configValue & 4) == 4) {
          this.configValue1 = 4
        } else {
          this.configValue1 = 0
        }
        if ((row.record.configValue & 1) == 1) {
          this.configValue2 = true
        } else {
          this.configValue2 = false
        }

        getRichText(row.record.desUri).then(res => {
          this.msg = res.data
        })
      } else {//删除
        this.dialogVisibleDell = true
        this.courseId = command.row.record.id
      }
    },
    deleteC() {//删除课程
      const params = {
        brandId: this.brandId,
        id: this.courseId
      }
      courseDele(params).then(res => {
        if (res.data.code == 200) {
          this.$message.success('删除成功')
          this.getcourseList()
          this.dialogVisibleDell = false
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    composeValue(item, row) {
      return {
        type: item,
        row: row
      }
    },
    changeTitle(id) {//点击右边标签
      this.categoryId = this.recordId = id
      this.getcourseList()
    },
    upload(selectFileobj) {
      let that = this;
      cos.options.getAuthorization("", function (obj) {
        let name = selectFileobj.name.replace(/MP4/g, "mp4");
        if (that.levelId == '') {
          that.$message.error('请先选择等级')
          document.getElementById('file').value = ''
          return
        }
        let filename = 'content/resource/LV' + that.levelId + '/' + 'video/' +
          name.slice(0, name.length - 4) +
          new Date().getTime() +
          name.slice(-4);
        console.log(filename)
        cos.sliceUploadFile(
          {
            Bucket: sessionStorage.getItem("bucket") /* 必须 */,
            Region: "ap-nanjing" /* 存储桶所在地域，必须字段 */,
            Key: filename /* 必须 */,
            Body: selectFileobj /* 必须 */,
            onTaskReady: function (taskId) {
              /* 非必须 */
              console.log(taskId);
              that.taskId = taskId
            },
            onHashProgress: function (progressData) {
              /* 非必须 */
              console.log(JSON.stringify(progressData));
            },
            onProgress: function (progressData) {
              /* 非必须 */
              console.log(JSON.stringify(progressData));
              that.percentage = parseInt(progressData.percent * 100);
            },
          },
          function (err, data) {
            console.log(err || data);
            if (data) {
              let Location = data.Location.replace(/content-1308336073.cos.ap-nanjing.myqcloud.com/g, 'https://sihuankangyuan.cn')
              // that.videoUri = "https://" + data.Location;
              that.videoUri = Location

              sessionStorage.setItem('videoUri', Location)

              setTimeout(() => {
                //   var videosrc = document.getElementById('videosrc');
                // that.duration=parseInt(videosrc.duration)
                // console.log(parseInt(videosrc.duration))
                that.$message({
                  message: `上传成功，视频正在转码请稍后再播放`,
                  type: 'warning',
                });
              }, 1000)
            }
          }
        );
      });
    },
    quillchange(delta, oldDelta, source) {
      console.log(delta, oldDelta, source)
    },

    uploadimgpicUri(e) {//上传课程包封面
      if (this.$refs['fileimgCourse'].files[0]) {
        let selectFileobj = this.$refs['fileimgCourse'].files[0]
        uploadImg(selectFileobj, (res) => {
          this.picUri = res
        })
      }
    },
    uploadimgpic(e) {//上传推荐封面
      if (this.$refs['fileimgPic'].files[0]) {
        let selectFileobj = this.$refs['fileimgPic'].files[0]
        uploadImg(selectFileobj, (res) => {
          this.homeItem = {
            picUri: res,
            isValidity: true,
            id: this.homeItem.id
          }
        })
      }
    },
    // upload2(selectFileobj) {
    //   let that = this
    //   let name = selectFileobj.name
    //   let filename = 'content/resource/newManage/image/' +
    //     name.slice(0, name.length - 4) +
    //     new Date().getTime() +
    //     name.slice(-4);
    //   console.log(filename)
    //   cos.options.getAuthorization('', function (obj) {
    //     cos.putObject({
    //       Bucket: sessionStorage.getItem('bucket'), /* 必须 */
    //       Region: 'ap-nanjing',     /* 存储桶所在地域，必须字段 */
    //       Key: filename,              /* 必须 */
    //       StorageClass: 'STANDARD',
    //       Body: selectFileobj, // 上传文件对象
    //       onProgress: function (progressData) {
    //         // console.log(JSON.stringify(progressData));
    //         console.log(progressData)
    //       }
    //     }, function (err, data) {
    //       console.log(err || data);
    //       if (data) {
    //         // let url='https://'+data.Location
    //         let picUri = data.Location.replace(/content-1308336073.cos.ap-nanjing.myqcloud.com/g, 'https://sihuankangyuan.cn')
    //         that.picUri = picUri
    //       }
    //     });
    //   })
    // },
    async getList() {
      const params = {
        brandId: this.brandId,
        parentId: this.id
      }
      const { data } = await currentCourseList(params)
      if (data.code == 200) {
        this.tableData = data.data
        console.log(data.data)
      }
    },
    dele(row) {
      console.log(row)
      const params = {
        courseId: row.id,
        brandId: this.brandId
      }
      this.$confirm('此操作将永久删除该项, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        courseDeleteAll(params).then(res => {
          if (res.data.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getList()
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            });
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    goback() {
      this.$router.go(-1)
    },
    async leave() {
      //获取等级列表
      const { data } = await levelList({ roleId: 3 });
      if (data.code == 200) {
        this.levelLists = data.data;
      } else {
        this.$message.error(data.msg);
      }
    },
    lookvideo(row) {
      this.$router.push({ path: '/courseAdd', query: row })
    },
    lookcontent2(row) {
      this.$router.push({ path: '/courseContent', query: row })
    },
    addtext(row) {
      this.$router.push({ path: '/courseContent', query: { id: row.id, brandId: this.brandId } })
    },
    addtext2(row) {
      this.$router.push({ path: '/coursePageList3', query: { id: row.id, brandId: this.brandId } })
    },
    addtext4(row) {
      this.$router.push({ path: '/exercises', query: { id: row.record.id, brandId: this.brandId } })
    },
    lookcontent(row) {
      this.$router.push({ path: '/contentAdd', query: row })
    },
    async list() {//课程包列表
      const params = {
        brandId: this.brandId,
        levelId: this.leveId,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        // contentType:this.radio,
        searchStr: this.input
      }
      const { data } = await courseInfoList(params)
      if (data.code == 200) {
        this.tableData = data.data.records,
          this.total = Number(data.data.total)
      }
      console.log(data)
    },
    details(row) {
      // this.$router.push({ path: '/courseChildAdd3', query: { row: JSON.stringify(row), brandId: this.brandId } })
      this.dialogVisibleContent = true
      this.courseId = row.record.id
      this.courseName = row.record.name
      this.getcontentList()
    },
    details2(row) {
      this.$router.push({ path: '/courseParentAdd3', query: { row: JSON.stringify(row), brandId: this.brandId } })
    },
    lookcontent3(row) {
      this.$router.push({ path: '/courseChildAdd2', query: { id: row.id, brandId: this.brandId } })
    },
    lookcontent4(row) {
      this.$router.push({ path: '/coursePageList2', query: { id: row.id, brandId: this.brandId } })
    },
    addcontent() {
      this.$router.push({ path: '/courseParentAdd', query: { id: this.id, brandId: this.brandId } })
      //   if(this.radio=='3'){
      //     this.$router.push({path:'/contentAdd'})
      //   }else{
      //     this.$router.push({path:'/videoAdd'})
      //   }
    },
    addcontent2() {
      this.$router.push({ path: '/courseChildAdd', query: { id: this.id, brandId: this.brandId } })
      //   if(this.radio=='3'){
      //     this.$router.push({path:'/contentAdd'})
      //   }else{
      //     this.$router.push({path:'/videoAdd'})
      //   }
    },
    search() {
      console.log("我是搜索");
    },
    handleSelectionChange(val) {//选中
      console.log(val)
      if (val.length > 0) {
        this.isdisabled = false
      } else {
        this.isdisabled = true
      }
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val
      this.list()
    },
    // handleCurrentChange(val) {
    //   console.log(`当前页: ${val}`);
    //   this.pageIndex = val
    //   this.list()
    // },

    deleall() {
      this.$confirm('此操作将永久删除选中项, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    }
  },
};
</script>

<style lang='less' scoped>
/deep/ .dialog1 .el-dialog__body {
  height: 85%;
  overflow: auto;
  padding: 0 20px;
}

/deep/.mywrap .el-dialog {
  //分段介绍，知识点
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  height: 80%;
  display: flex;
  flex-direction: column;
}
/deep/.mywrapAdd .el-dialog {
  //分段介绍，知识点
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  height: 50%;
  display: flex;
  flex-direction: column;
}

/deep/.mywrap .el-dialog .el-dialog__body {
  //分段介绍，知识点
  flex: 1;
  padding: 0 20px;
  overflow: auto;
}
/deep/.mywrapAdd .el-dialog .el-dialog__body {
  //分段介绍，知识点添加
  flex: 1;
  padding: 0 20px;
  overflow: auto;
}

/deep/ .dialog1 .el-dialog {
  margin: 0 !important;
  float: right;
  height: 100%;
}

.titleLayout {
  display: flex;
  border-radius: 8px 8px 0 0;
  background: #C6E7E2;
  height: 34px;
  line-height: 34px;
}

.currentTitle {
  background: #fff;
  border-radius: 8px 8px 0 0;
  height: 34px;
  line-height: 34px;
}

.main {
  // min-height: 800px;
  // background: #fff;
  margin: 19px 0;
  padding: 23px;
  border-radius: 14px;
}

// .main>div:first-child {
//   font-size: 26px;
//   // font-weight: bold;
// }

// .main>div:nth-child(2) {
//   display: flex;
//   justify-content: space-between;
//   height: 34px;
//   margin: 18px 0;

//   div {
//     display: flex;

//     img {
//       width: 25px;
//       height: 25px;
//     }
//   }
// }
.changeSel {
  padding: 8px 22px;
  background: #2FAFA1;
  border-radius: 50px;
  color: #fff;
}

/deep/.el-radio-button__inner {
  height: 34px;
  line-height: 10px;
}

.cinput /deep/.el-input__inner {
  height: 34px;
  line-height: 10px;
}

/deep/.el-button--primary {
  height: 34px;
  line-height: 10px;
}

/deep/.el-table::before {
  height: 0;
}

.ac /deep/.el-input__inner {
  border: none;
  background: #f7f7f7;
  // margin-right: 11px;
}

.defaultcolor {
  color: #3C4645;
}

.red {
  color: #ff1b0b;
}

/deep/.el-radio__input.is-checked+.el-radio__label {
  color: #00A78E;
}

/deep/.el-radio__input.is-checked .el-radio__inner {
  border-color: #00A78E;
  background: #00A78E
}

.selectContent {
  color: #00A78E;
}

.cstatus1 {
  color: #FAAD14;
}

.cstatus2 {
  color: #54C97F;
}

.cstatus3 {
  color: #ACB6B5;
}

.hand:hover {
  cursor: pointer;
}

.typeWrap {
  margin: 10px 0;
}

.typeWrap:hover {
  cursor: pointer;
}

.typeWrap>div {
  border: 1px solid #CDD3D2;
  border-right: none;
}

.typeWrap>div:last-child {
  border: 1px solid #CDD3D2;
  border-radius: 0 8px 8px 0;
}

.typeWrap>div:first-child {
  border: 1px solid #CDD3D2;
  border-radius: 8px 0 0 8px;
}

.typeWrap>div.selectLevel {
  border: 1px solid #00A78E;
  color: #00A78E;
}

.line {
  width: 4px;
  height: 13px;
  background: #00A78E;
  border-radius: 50px;
  margin-right: 5px;
}
</style>
